<template>
  <v-autocomplete
    v-model="localValue"
    :items="items"
    :label="label"
    item-text="name"
    item-value="id"
    ref="select"
    @input="handleInput"
    :search-input.sync="newItem.name"
    prepend-icon="mdi-tools"
  >
    <template v-slot:append-item>
      <v-dialog v-model="dialog" max-width="600px">
        <template v-slot:activator="{ on, attrs }">
          <v-btn class="ml-4" color="primary" outlined small v-bind="attrs" v-on="on">Create New {{ itemType }}</v-btn>
        </template>
        <v-card>
          <v-toolbar class="elevation-2" color="grey lighten-3 mb-5">
            <v-toolbar-title class="headline primary--text">Create New {{ itemType }}</v-toolbar-title>
            <v-spacer></v-spacer>
            <toolbar-icon-button tooltip="Save" icon="mdi-content-save" color="primary" @click="save" />
            <toolbar-icon-button tooltip="Close" icon="mdi-close" color="error" @click="close" />
          </v-toolbar>

          <v-card-text>
            <v-container>
              <v-row>
                <v-col cols="12">
                  <v-text-field v-model="newItem.name" :label="`${itemType} Name`"></v-text-field>
                  <v-text-field v-model="newItem.code" :label="`${itemType} Code`"></v-text-field>
                </v-col>
              </v-row>
            </v-container>
          </v-card-text>
        </v-card>
      </v-dialog>
    </template>
  </v-autocomplete>
</template>

<script>
import ToolbarIconButton from '@/components/Inputs/ToolbarIconButton.vue'

export default {
  name: "companySelectAndCreate",
  components: {
    ToolbarIconButton,
  },
  data() {
    return {
      items: [],
      search: '',
      loading: true,
      dialog: false,
      localValue: null,
      newItem: {
        name: "",
        code: "",
      }
    }
  },
  props: {
    value: {
      type: Number,
      default: 0
    },
    label: {
      type: String,
      default: 'Select an option'
    },
    tenant_id: {
      type: Number,
      required: true
    },
    itemType: {
      type: String,
      required: true
    },
  },
  watch: {
    value(newValue) {
      this.localValue = newValue
    }
  },
  created() {
    this.localValue = this.value
    this.initialize()
  },
  methods: {
    handleInput(value) {
      this.$emit('input', value)
    },
    async initialize() {
      try {
        let response = await this.$http.get('/global/company_list')
        this.items = response.data.companies.filter(c => c.company_type == this.itemType)
        if (this.itemType == "Customer") {
          this.items = this.items.filter(c => c.tenant_id == this.tenant_id)
        }
        this.loading = false
      } catch (error) {
        this.$toast.error(`Failed to load ${this.itemType}s.`)
      }
    },
    async save() {
      try {
        const itemData = {
          name: this.newItem.name,
          code: this.newItem.code,
          tenant_id: this.tenant_id,
          company_type: this.itemType,
        }
        let response = await this.$http.post('/tenant/companies', itemData)

        if (response.data.error) {
          Array.isArray(response.data.error)
            ? response.data.error.forEach(m => this.$toast.error(m))
            : this.$toast.error(response.data.error)
        } else {
          this.items = [...this.items, response.data.company]
          this.localValue = response.data.company.id
          this.$emit('input', this.localValue)
          this.$refs.select.blur()
          this.$toast.success(`${this.itemType} Created`)
        }
        this.close()
      } catch (error) {
        this.$toast.error(`An error occurred while creating the ${this.itemType.toLowerCase()}.`)
      }
    }, 
    close() {
      this.dialog = false
      this.newItem = { name: "", code: "" }
    }
  },
}
</script>