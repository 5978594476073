const getDefaultState = () => ({
  tenantUsers: [],
  tenantTenant: {},
  tenantSites: [],
  tenantSelectedUser: {},
  tenantUserCardDialog: false,
})

const state = getDefaultState()

const getters = {
  tenantUsers: state => state.tenantUsers,
  tenantTenant: state => state.tenantTenant,
  tenantSites: state => state.tenantSites,
  tenantSelectedUser: state => state.tenantSelectedUser,
  tenantUserCardDialog: state => state.tenantUserCardDialog,
};

const mutations = {
  resetTenantState(state) {
    Object.assign(state, getDefaultState());
  },
  setTenantData (state, data) {
    state.tenantUsers = data.users
    state.tenantTenant = data.tenant
    state.tenantSites = data.sites
  },
  clearTenantData (state) {
    state.tenantUsers = []
    state.tenantTenant = {}
    state.tenantSites = []
  },
  openTenantNewUserCard (state, tenant_id) {
    state.tenantSelectedUser = {
      username: '',
      password: '',
      email: '',
      fname: '',
      role: 1,
      lname: '',
      tenant_id: tenant_id,
      user_status: true,
      site_accesses: [],
    }
    state.tenantUserCardDialog = true
  },
  openTenantUserCard (state, id) {
    state.tenantSelectedUser = state.tenantUsers[state.tenantUsers.findIndex(a => a.id === id)]
    state.tenantUserCardDialog = true
  },
  closeTenantUserCard (state) {
    state.tenantUserCardDialog = false
    state.tenantSelectedUser = {
      username: '',
      password: '',
      email: '',
      fname: '',
      lname: '',
      tenant_id: '',
      user_status: true,
      site_accesses: [],
    }
  },

  createTenantUser (state, user) {
    state.tenantUsers.push(user)
  },

  updateTenantUser (state, user) {
    Object.assign(state.tenantUsers[state.tenantUsers.findIndex(a => a.id === user.id)], user)
  },

  deleteTenantUser (state, id) {
    state.tenantUsers = state.tenantUsers.filter(a => a.id !== id)
  },

  updateTenantSiteAccess (state, siteAccess) {
    Object.assign(state.tenantSelectedUser.site_accesses[state.tenantSelectedUser.site_accesses.findIndex(a => a.id === siteAccess.id)], siteAccess)
  },
  createTenantSiteAccess (state, siteAccess) {
    state.tenantSelectedUser.site_accesses.push(siteAccess)
  },
};

export default {
  state,
  getters,
  mutations
};