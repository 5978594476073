<template>
  <div>
    <v-list-item @click="selectFile">
      <v-list-item-icon>
        <v-icon>mdi-file-import-outline</v-icon>
      </v-list-item-icon>

      <v-list-item-content>
        Import Companies
      </v-list-item-content>
      <v-file-input
        accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
        @change="excelImport"
        ref="fileInput"
        name="excel-input"
        id="input"
        class="d-none"
      ></v-file-input>
    </v-list-item>
    <v-dialog v-model="dialog">
      <v-card min-height="600px">
        <v-toolbar class="elevation-2" color="grey lighten-3 mb-5">
          <v-toolbar-title class="headline primary--text">Import Companies Wizard</v-toolbar-title>
          <v-spacer></v-spacer>
          <v-tooltip top>
            <template v-slot:activator="{ on }">
              <v-btn icon v-on="on" @click="save" color="primary">
                <v-icon>mdi-upload</v-icon>
              </v-btn>
            </template>
            <span>Upload</span>
          </v-tooltip>
          <v-tooltip top>
            <template v-slot:activator="{ on }">
              <v-btn icon v-on="on" @click="dialog = false" color="error">
                <v-icon>mdi-close</v-icon>
              </v-btn>
            </template>
            <span>Close</span>
          </v-tooltip>
        </v-toolbar>
        <v-container>
          <v-row align="center">
            <v-col cols="12">
              <v-data-table
                :headers="excelHeaders"
                :items="excelData"
                :items-per-page="5"
                :loading="loading"
                loading-text="Processing... Please wait"
                class="elevation-1"
              ></v-data-table>
            </v-col>
          </v-row>
        </v-container>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import readXlsxFile from 'read-excel-file'

export default {
  name: "XlsCompaniesUploader",
  data() {
    return {
      excelData: [],
      excelHeaders: [],
      errorCompanies: [],
      loading: false,
      dialog: false,
    };
  },
  computed: {
  },
  methods: {
    selectFile() {
      let fileInputElement = document.getElementById('input')
      fileInputElement.click();
    },
    excelImport () {
      this.excelData = []
      this.excelHeaders = []
      readXlsxFile(document.getElementById('input').files[0]).then((rows) => {
        let companies = []
        let headers = rows.shift()
        rows.forEach((row) => {
          const obj = {}
          row.forEach((prop, index) => {
            obj[headers[index]] = prop
          })
          companies.push(obj)
        })
        this.excelData = companies
        headers.forEach((header) => {
          let h = {}
          h["value"] = header
          h["text"] = header
          this.excelHeaders.push(h)
        })
      })
      this.dialog = true
    },
    async save() {
      this.loading = true
      this.excelData.forEach( async (company) => {
        const Company = new FormData()
        if (company.name != null) { Company.append('name', company.name)}
        if (company.code != null) { Company.append('code', company.code)}
        if (company.tenant_id != null) { Company.append('tenant_id', company.tenant_id)}
        if (company.company_type != null) { Company.append('company_type', company.company_type)}
        
        if (company.id != null) { 
          Company.append('id', company.id)
          let response = await this.$http.put('/admin/companies', Company)
          if (response.data.error) { this.errorCompanies.push(company) } 
        } else {
          let response = await this.$http.post('/admin/companies', Company)
          if (response.data.error) { this.errorCompanies.push(company) } 
        }
      })
      
      if (this.errorCompanies.length === 0) {
        this.$toast.success("Companies uploaded")
        this.dialog = false
        this.excelData = []
        this.excelHeaders = []
        this.errorCompanies = []
        this.$emit('uploaded')
      } else {
        this.$toast.error("Upload incomplete check errors")
      }
      this.loading = false
    },
  },
};
</script>