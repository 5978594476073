<template>
  <v-container fluid class="products-page mt-5">
    <v-row>
      <v-col cols="12">
        <v-data-table
          :headers="headers"
          :items="products"
          :search="search"
          :loading="loading"
          sort-by="id"
          class="elevation-1"
          fixed-header
          height="72vh"
          @click:row="editItem"
        >
          <template v-slot:top>
            <v-toolbar flat height="90">
              <v-menu v-model="menu" :close-on-content-click="false" :nudge-width="200" offset-x>
                <template v-slot:activator="{ on, attrs }">
                  <v-btn icon v-bind="attrs" v-on="on">
                    <v-icon>mdi-menu</v-icon>
                  </v-btn>
                </template>

                <v-card>
                  <v-list nav dense>
                    <xls-products-uploader @uploaded="initialize"></xls-products-uploader>
                    <xls-products-downloader :products="products"></xls-products-downloader>
                  </v-list>
                </v-card>
              </v-menu>
              <v-spacer></v-spacer>
              <table-search-field v-model="search"></table-search-field>
              <v-spacer></v-spacer>

              <create-product @done="initialize" :tenants="tenants" :procats="procats" admin/>
              <update-product @done="initialize" :item="item" v-model="dialogVisible" :tenants="tenants" :procats="procats" admin/>

            </v-toolbar>
          </template>
          <template v-slot:[`item.image`]="{ item }">
            <div class="p-2">
              <v-img :src="item.image" :alt="item.name" max-height="80px" max-width="100px" contain></v-img>
            </div>
          </template>
          <template v-slot:[`item.tenant_id`]="{ item }">
            {{ showName(item.tenant_id, 'tenants') }}
          </template>
          <template v-slot:[`item.brand_id`]="{ item }">
            {{ showName(item.company_id, 'companies') }}
          </template>
          <template v-slot:[`item.procat_id`]="{ item }">
            {{ showName(item.procat_id, 'procats') }}
          </template>
          <template v-slot:[`item.created_at`]="{ item }">
            {{ item.created_at | moment($store.getters.dateFormat) }}
          </template>
          <template v-slot:[`item.updated_at`]="{ item }">
            {{ item.updated_at | moment($store.getters.dateFormat) }}
          </template>
          <template v-slot:no-data>
            <v-btn color="primary" @click="initialize">Reset</v-btn>
          </template>
        </v-data-table>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
  import CreateProduct from '@/components/Forms/Product/CreateProduct'
  import UpdateProduct from '@/components/Forms/Product/UpdateProduct'
  import XlsProductsDownloader from '@/components/Xls/XlsProductsDownloader'
  import XlsProductsUploader from '@/components/Xls/XlsProductsUploader'
  import TableSearchField from '@/components/Inputs/TableSearchField' 

  export default {
    name: 'Products',
    components: {
      XlsProductsDownloader,
      XlsProductsUploader,
      CreateProduct,
      UpdateProduct,
      TableSearchField
    },
    data: () => ({
      products: [],
      tenants: [],
      procats: [],
      companies: [],
      menu: false,
      search: '',
      loading: true,
      dialogVisible: false,
      headers: [
        { text: 'ID', value: 'id' },
        { text: 'Image', value: 'image', sortable: false },
        { text: 'Brand', value: 'brand_id' },
        { text: 'Model', value: 'product_model' },
        { text: 'Description', value: 'name' },
        { text: 'Category', value: 'procat_id' },
        { text: 'Tenant', value: 'tenant_id' },
        { text: 'Created at', value: 'created_at' },
        { text: 'Updated at', value: 'updated_at' },
      ],
      item: {},
    }),

    created () {
      this.initialize()
      this.$store.commit('setHeaderTitle', 'Admin > Products')
    },

    methods: {
      async initialize () {
        let response = await this.$http.get('/admin/products')
        this.products = response.data.products
        this.tenants = response.data.tenants
        this.procats = response.data.procats
        this.companies = response.data.companies
        this.loading = false
      },

      editItem (item) {
        this.dialogVisible = true
        this.item = Object.assign({}, item)
      },
      
      showName(id, type) {
        const data = this[type]
        if (!data) return 'Unknown'

        const item = data.find(obj => obj.id === id)
        return item ? item.name : 'Unknown'
      },

    },
  }
</script>