<!-- IconButton.vue -->
<template>
  <v-tooltip top>
    <template v-slot:activator="{ on }">
      <v-btn icon v-on="on" @click="handleClick" :color="color">
        <v-icon>{{ icon }}</v-icon>
      </v-btn>
    </template>
    <span>{{ tooltip }}</span>
  </v-tooltip>
</template>

<script>
export default {
  props: {
    tooltip: { type: String, required: true },
    icon: { type: String, required: true },
    color: { type: String, default: 'primary' },
  },
  methods: {
    handleClick() {
      this.$emit('click');
    },
  },
};
</script>
