const getDefaultState = () => ({
  adminUsers: [],
  adminTenants: [],
  adminSites: [],
  adminSelectedUser: {
    username: '',
    password: '',
    email: '',
    fname: '',
    lname: '',
    tenant_id: '',
    user_status: true,
    site_accesses: [],
  },
  adminUserCardDialog: false,
})

const state = getDefaultState()

const getters = {
  adminUsers: state => state.adminUsers,
  adminTenants: state => state.adminTenants,
  adminSites: state => state.adminSites,
  adminSelectedUser: state => state.adminSelectedUser,
  adminUserCardDialog: state => state.adminUserCardDialog,
}

const mutations = {
  setAdminData(state, data) {
    state.adminUsers = data.users
    state.adminTenants = data.tenants
    state.adminSites = data.sites
  },
  clearAdminData(state) {
    state.adminUsers = []
    state.adminTenants = []
    state.adminSites = []
  },
  openAdminNewUserCard(state) {
    state.adminSelectedUser = {
      username: '',
      password: '',
      email: '',
      fname: '',
      lname: '',
      tenant_id: '',
      user_status: true,
      site_accesses: [],
    }
    state.adminUserCardDialog = true
  },
  openAdminUserCard(state, id) {
    const user = state.adminUsers.find(a => a.id === id)
    state.adminSelectedUser = user ? { ...user } : getDefaultState().adminSelectedUser
    state.adminUserCardDialog = true
  },
  closeAdminUserCard(state) {
    state.adminUserCardDialog = false
    state.adminSelectedUser = getDefaultState().adminSelectedUser
  },
  createAdminUser(state, user) {
    state.adminUsers.push(user)
  },
  updateAdminUser(state, user) {
    const index = state.adminUsers.findIndex(a => a.id === user.id)
    if (index !== -1) Object.assign(state.adminUsers[index], user)
  },
  deleteAdminUser(state, id) {
    state.adminUsers = state.adminUsers.filter(a => a.id !== id)
  },
  updateAdminSiteAccess(state, siteAccess) {
    const index = state.adminSelectedUser.site_accesses.findIndex(a => a.id === siteAccess.id)
    if (index !== -1) Object.assign(state.adminSelectedUser.site_accesses[index], siteAccess)
  },
  createAdminSiteAccess(state, siteAccess) {
    state.adminSelectedUser.site_accesses.push(siteAccess)
  },
  // Reset state to default values
  resetAdminState(state) {
    Object.assign(state, getDefaultState())
  },
}

export default {
  state,
  getters,
  mutations,
}
