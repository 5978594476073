<template>
  <v-menu
    v-model="menuVisible"
    :close-on-content-click="false"
    :nudge-right="40"
    transition="scale-transition"
    offset-y
    min-width="290px"
  >
    <template v-slot:activator="{ on, attrs }">
      <v-text-field
        :value="computedDate"
        :label="label"
        :prepend-icon="icon"
        v-bind="attrs"
        v-on="on"
        clearable
        readonly
        :persistent-hint="persistent_hint"
        :hint="hint"
        @click:clear="clear"
      ></v-text-field>
    </template>
    <v-date-picker
      v-model="date"
      @input="onDateSelected"
    ></v-date-picker>
  </v-menu>
</template>

<script>
export default {
  name: 'DatePickerMenu',
  props: {
    value: { type: String, default: ''},
    label: { type: String, default: 'Select Date'},
    icon: { type: String, default: 'mdi-calendar'},
    persistent_hint: { type: Boolean, default: false},
    hint: { type: String, default: ''}
  },
  data() {
    return {
      menuVisible: false,
      date: this.value
    };
  },
  computed: {
    computedDate() {
      return this.date ? this.$moment(String(this.date)).format('DD/MM/YYYY') : ''
    }
    
  },
  watch: {
    value(newValue) {
      this.date = newValue
    }
  },
  methods: {
    onDateSelected(value) {
      this.menuVisible = false
      this.$emit('input', value)
    },
    clear() {
      this.date = ''
      this.$emit('input', '')
    }
  }
};
</script>