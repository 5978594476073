// Define the initial state in a function to allow resetting
const getDefaultState = () => ({
  title: "Wikibuild"
})

const state = getDefaultState()

const getters = {
  HeaderTitle: state => state.title
}

const actions = {
  async setHeaderTitle({ commit }, title) {
    commit('setHeaderTitle', title)
  }
}

const mutations = {
  setHeaderTitle(state, title) {
    state.title = title
  },
  resetHeaderState(state) {
    Object.assign(state, getDefaultState())
  }
}

export default {
  state,
  getters,
  actions,
  mutations
}
