// Define the initial state in a function to allow resetting
const getDefaultState = () => ({
  config: {
    bucketName: 'wtec-uploads',
    dirName: 'dev', /* change before production remove dev/ */
    region: 'eu-central-1',
    accessKeyId: 'AKIA4QDQXYHY6FMS2YTZ',
    secretAccessKey: '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',
    s3Url: 'https://wtec-uploads.s3.eu-central-1.amazonaws.com', /* optional */
  }
})

const state = getDefaultState()

const getters = {
  s3Config: state => state.config,
}

const mutations = {
  resetS3State(state) {
    Object.assign(state, getDefaultState())
  }
}

export default {
  state,
  getters,
  mutations
}
