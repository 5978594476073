<template>
  <v-text-field
    v-model="localValue"
    :clearable="clearable"
    :flat="flat"
    :color="color"
    :hide-details="hideDetails"
    :prepend-inner-icon="prependInnerIcon"
    :label="label"
    @input="updateValue"
  ></v-text-field>
</template>

<script>
export default {
  name: 'TableSearchField',
  props: {
    value: { type: String, default: '' },
    clearable: { type: Boolean, default: true },
    flat: { type: Boolean, default: true },
    color: { type: String, default: 'primary' },
    hideDetails: { type: Boolean, default: true },
    prependInnerIcon: { type: String, default: 'mdi-magnify' },
    label: { type: String, default: 'Search' }
  },
  data() {
    return {
      localValue: this.value
    }
  },
  watch: {
    value(newValue) {
      this.localValue = newValue
    }
  },
  methods: {
    updateValue(value) {
      this.$emit('input', value)
    }
  }
}
</script>