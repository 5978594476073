// Define the initial state in a function to allow resetting
const getDefaultState = () => ({
  interfaceIO: ['input', 'output', 'bi-directional'],
  interfaceTerminal: [
    'RJ45', 'XLR', 'Power Supply', 'HDMI', 'RCA', 'CABLE', 'USB',
    'Jack 3.5', 'Jack 6.3', 'IEC', 'DC', 'XLR/PHOENIX', 'SPEAKON/PHOENIX',
    'PHOENIX', 'XLR/Jack 6.3', 'Optical', 'S/PDIF Coax', 'S/PDIF Tosslink',
    'Midi 5-pin', 'D-size slot', 'VGA', 'DVI', 'Displayport', 'BNC',
    'RS-232', 'SFP', 'RJ10', 'RJ9'
  ],
  interfaceSignal: ['Data', 'Audio', 'AC/DC', 'Video', 'Power'],
  locationTypes: ['room', 'space', 'outdoor area', 'Meeting Room', 'Meeting Room Teams', 'Workspace'],
  companyTypes: ['Customer', 'Brand', 'Supplier', 'Contractor'],
  defaultProduct: {
    name: '',
    image: '',
    product_model: '',
    product_url: '',
    product_status: true,
    description: '',
    features: '',
    height: '',
    width: '',
    depth: '',
    weight: '',
    rack_mounted: false,
    rack_units: '',
    sku: '',
    upc: '',
    ean: '',
    power: '',
    brand: '',
    tenant_id: 1,
    procat_id: null,
    company_id: null,
    brand_id: null,
    preferred_supplier_id: null,
    interfaces: [],
    discarded_interfaces: []
  },
  defaultInterface: {
    product_id: null,
    interface_type: null,
    label: null,
    signal: null,
    terminal: null,  
  }
})

const state = getDefaultState()

const getters = {
  interfaceIO: state => state.interfaceIO,
  interfaceTerminal: state => state.interfaceTerminal,
  interfaceSignal: state => state.interfaceSignal,
  locationTypes: state => state.locationTypes,
  companyTypes: state => state.companyTypes,
  defaultProduct: state => state.defaultProduct,
  defaultInterface: state => state.defaultInterface
}

const mutations = {
  resetConstantsState(state) {
    Object.assign(state, getDefaultState())
  }
}

export default {
  state,
  getters,
  mutations
}
