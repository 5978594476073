// Define the initial state in a function to allow resetting
const getDefaultState = () => ({
  dateFormat: "DD/MM/YYYY",
  dateTimeFormat: "DD/MM/YYYY HH:mm",
  timeFormat: "HH:mm",
  toastOptions: {
    position: "top-right",
    timeout: 3000,
    closeOnClick: true,
    pauseOnHover: false,
    draggable: true,
    draggablePercent: 0.6,
    showCloseButtonOnHover: true,
    hideProgressBar: true,
    closeButton: "button",
    icon: true
  },
  adminUserRoles: [
    { value: 1, text: "User" },
    { value: 2, text: "Building Owner" },
    { value: 3, text: "Integrator " },
    { value: 4, text: "Library Admin" },
    { value: 5, text: "Super Admin" }
  ],
  tenantUserRoles: [
    { value: 1, text: "User" },
    { value: 2, text: "Building Owner" },
    { value: 3, text: "Integrator " }
  ]
})

const state = getDefaultState()

const getters = {
  dateFormat: state => state.dateFormat,
  dateTimeFormat: state => state.dateTimeFormat,
  timeFormat: state => state.timeFormat,
  toastOptions: state => state.toastOptions,
  adminUserRoles: state => state.adminUserRoles,
  tenantUserRoles: state => state.tenantUserRoles,
}

const mutations = {
  resetSettingsState(state) {
    Object.assign(state, getDefaultState())
  }
}

export default {
  state,
  getters,
  mutations
}
