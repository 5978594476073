<template>
  <v-list-item @click="download">
    <v-list-item-icon>
      <v-icon>mdi-file-export-outline</v-icon>
    </v-list-item-icon>
    <v-list-item-content>
      Export Companies
    </v-list-item-content>
  </v-list-item>
</template>

<script>
import { utils, writeFile } from "xlsx"

export default {
  name: "XlsCompaniesDownloader",
  data() {
    return {
      exportData: [],
      filename: "Wikibuild - Companies.xlsx"
    };
  },
  props: ['companies'],
  computed: {
  },
  methods: {
    download () {
      this.companies.forEach( (company) => {
        let row = {}
        row.id = company.id
        row.name = company.name
        row.code = company.code
        row.tenant_id = company.tenant_id
        row.company_type = company.company_type

        row.customer = company.customer
        row.brand = company.brand
        row.supplier = company.supplier

        this.exportData.push(row)
      })
      const comp = utils.json_to_sheet(this.exportData)
      const wb = utils.book_new()
      utils.book_append_sheet(wb, comp, 'Companies')
      writeFile(wb, this.filename)
    },
  },
};
</script>