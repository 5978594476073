<template>
  <v-menu offset-y bottom right nudge-bottom="15" min-width="300px" transition="scale-transition">
    <template v-slot:activator="{ on, attrs }">
      <v-btn class="mr-0" v-bind="attrs" v-on="on" rounded text>
        <v-icon style="font-size: 28px" left> mdi-account</v-icon>
        {{StateUser.email}}
      </v-btn>
    </template>
    <v-list>
      <div class="text-h5 grey--text text--darken-3 px-4 pt-4">{{StateUser.fname}} {{StateUser.lname}}</div>
      <div class="subtitle-2 primary--text font-weight-regular px-4">{{UserRole}}</div>
      <v-list-item-group color="primary">
        <v-list-item
          v-for="(item, i) in account"
          :key="i"
          :to="item.link === '#' ? null : item.link">
            <v-list-item-icon class="mr-4">
              <v-icon :color="item.color">
                {{ item.icom }}
              </v-icon>
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title >{{ item.text }}</v-list-item-title>
            </v-list-item-content>
        </v-list-item>
      </v-list-item-group>
      <v-list-item-group color="primary" v-if="UserRole === 'Super Admin'">
        <v-list-item
          v-for="(item, i) in superAdminAccount"
          :key="i"
          :to="item.link === '#' ? null : item.link">
            <v-list-item-icon class="mr-4">
              <v-icon :color="item.color">
                {{ item.icom }}
              </v-icon>
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title>{{ item.text }}</v-list-item-title>
            </v-list-item-content>
        </v-list-item>
      </v-list-item-group>
      <div class="d-flex justify-center my-3">
        <v-btn
          width="80%"
          large
          outlined
          color="primary"
          class="text-capitalize"
          @click="signOut">Sign Out
        </v-btn>
      </div>
    </v-list>
  </v-menu>
</template>

<script>
  import {mapGetters} from 'vuex'
  
  export default {
    name: 'UserMenu',
    data: () => ({
      account: [
        { text: 'Profile', icon: 'mdi-account', color: 'textColor', link: '/profile' },
      ],
      superAdminAccount: [
        { text: 'Administration', icon: 'mdi-application-cog', color: 'textColor', link: '/admin/dashboard' }
      ],
    }),
    computed: {
      ...mapGetters(['UserRole', 'StateUser']),
    },
    mounted() {
    },
    methods: {
      async signOut() {
        await this.$store.dispatch('LogOut')
        this.$router.push('/login')
      }
    }
  };
</script>