<template>
  <v-container fluid class="companies-page mt-5">
    <v-row>
      <v-col cols="12">
        <v-data-table
          :headers="headers"
          :items="companies"
          :search="search"
          :loading="loading"
          sort-by="id"
          class="elevation-1 cpointer"
          fixed-header
          height="70vh"
          @click:row="editItem"
        >
          <template v-slot:top>
            <v-toolbar flat height="90">
              <v-menu
                v-model="menu"
                :close-on-content-click="false"
                :nudge-width="200"
                offset-x
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-btn icon v-bind="attrs" v-on="on">
                    <v-icon>mdi-menu</v-icon>
                  </v-btn>
                </template>

                <v-card>
                  <v-list nav dense>
                    <xls-companies-uploader @uploaded="initialize"></xls-companies-uploader>
                    <xls-companies-downloader :companies="companies"></xls-companies-downloader>
                  </v-list>
                </v-card>
              </v-menu>
              <v-spacer></v-spacer>
              <v-text-field
                v-model="search"
                clearable
                flat
                color="primary"
                hide-details
                prepend-inner-icon="mdi-magnify"
                label="Search"
              ></v-text-field>
              <v-spacer></v-spacer>
              <create-company @done="initialize"></create-company>
              <update-company @done="initialize" :item="item" v-model="dialogVisible"></update-company>
            </v-toolbar>
          </template>
          <template v-slot:[`item.tenant_id`]="{ item }">
            {{ showTenantName(item.tenant_id) }}
          </template>
          <template v-slot:[`item.created_at`]="{ item }">
            {{ item.created_at | moment($store.getters.dateFormat) }}
          </template>
          <template v-slot:[`item.updated_at`]="{ item }">
            {{ item.updated_at | moment($store.getters.dateFormat) }}
          </template>
          <template v-slot:no-data>
            <v-btn color="primary" @click="initialize">Reset</v-btn>
          </template>
        </v-data-table>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
  import { mapGetters } from 'vuex'
  import CreateCompany from '@/components/Forms/Company/CreateCompany'
  import UpdateCompany from '@/components/Forms/Company/UpdateCompany'
  import XlsCompaniesDownloader from '@/components/Xls/XlsCompaniesDownloader'
  import XlsCompaniesUploader from '@/components/Xls/XlsCompaniesUploader'

  export default {
    name: 'Companies',
    components: {
      CreateCompany,
      UpdateCompany,
      XlsCompaniesUploader,
      XlsCompaniesDownloader,
    },
    data: () => ({
      companies: [],
      tenants: [],
      search: '',
      loading: true,
      menu: false,
      dialogVisible: false,
      headers: [
        { text: 'ID', value: 'id' },
        { text: 'Name', value: 'name' },
        { text: 'Code', value: 'code' },
        { text: 'Type', value: 'company_type' },
        { text: 'Tenant', value: 'tenant_id' },
        { text: 'Created at', value: 'created_at' },
        { text: 'Updated at', value: 'updated_at' },
      ],
      item: {},
    }),
    computed: {
      ...mapGetters(['companyTypes']),
    },
    created () {
      this.initialize()
      this.$store.commit('setHeaderTitle', 'Admin > Companies')
    },
    methods: {
      async initialize() {
        try {
          let response = await this.$http.get('/admin/companies')
          this.companies = response.data.companies
          this.tenants = response.data.tenants
        } catch (error) {
          this.$toast.error("Error fetching companies data")
        } finally {
          this.loading = false
          this.menu = false
        }
      },
      editItem (item) {
        this.dialogVisible = true
        this.item = Object.assign({}, item)
      },
      tenantsMap() {
        return this.tenants.reduce((map, tenant) => {
          map[tenant.id] = tenant.name
          return map
        }, {})
      },
      showTenantName(id) {
        const tenantMap = this.tenantsMap();
        return tenantMap[id] || 'Unknown Tenant';
      }
    },
  }
</script>