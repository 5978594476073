<template>
  <v-dialog v-model="dialog" max-width="800px">
    <template v-slot:activator="{ on, attrs }">
      <v-btn
        color="primary"
        dark
        class="mb-2"
        v-bind="attrs"
        v-on="on"
        v-if="StateUser.role >= 3"
      >New Site</v-btn>
    </template>
    <v-card>
      <v-toolbar class="elevation-2" color="grey lighten-3 mb-5">
        <v-toolbar-title class="headline primary--text">New Site</v-toolbar-title>
        <v-spacer></v-spacer>
        <v-tooltip top>
          <template v-slot:activator="{ on }">
            <v-btn icon v-on="on" @click="save" color="primary">
              <v-icon>mdi-content-save</v-icon>
            </v-btn>
          </template>
          <span>Save</span>
        </v-tooltip>
        <v-tooltip top>
          <template v-slot:activator="{ on }">
            <v-btn icon v-on="on" @click="close" color="error">
              <v-icon>mdi-close</v-icon>
            </v-btn>
          </template>
          <span>Close</span>
        </v-tooltip>
      </v-toolbar>

      <v-card-text>
        <v-container>
          <v-row>
            <v-col cols="12">
              <v-text-field
              v-model="editedItem.name"
              label="Name*"
              required
              ></v-text-field>
            </v-col>
            <v-col cols="12">
              <company-select-and-create v-model="editedItem.customer_id" label="Customer*" :tenant_id="StateUser.tenant_id" itemType="Customer"/>
            </v-col>
            <v-col cols="9">
              <v-text-field
              label="Street"
              v-model="editedItem.street"
              ></v-text-field>
            </v-col>
            <v-col cols="3">
              <v-text-field
              label="Street Number"
              v-model="editedItem.street_number"
              ></v-text-field>
            </v-col>
            <v-col cols="3">
              <v-text-field
              label="Postal Code"
              v-model="editedItem.zip"
              ></v-text-field>
            </v-col>
            <v-col cols="9">
              <v-text-field
              label="City"
              v-model="editedItem.city"
              ></v-text-field>
            </v-col>
            <v-col cols="12">
              <v-autocomplete
                v-model="editedItem.country"
                :items="countries"
                label="Country"
              ></v-autocomplete>
            </v-col>
            <v-col cols="12">
              <s3-file-uploader :obj="editedItem" fieldName="image" directory="sites" label="Upload Picture" @located="setFileLocation"/>
            </v-col>
            <v-col cols="12">
              <v-switch
                v-model="editedItem.site_status"
                inset
                :label="editedItem.site_status ? 'Active' : 'Inactive'"
              ></v-switch>
            </v-col>
            
          </v-row>
        </v-container>
      </v-card-text>

    </v-card>
  </v-dialog>
</template>

<script>
import { mapGetters } from 'vuex'
import S3FileUploader from '@/components/S3Upload/S3FileUploader'
import CompanySelectAndCreate from '@/components/Selectors/companies/CompanySelectAndCreate.vue'

export default {
  name: "CreateSite",
  components: {
    S3FileUploader,
    CompanySelectAndCreate,
  },
  data() {
    return {
      countries: ['Belgium', 'France', 'Luxemburg', 'Netherlands'],
      loading: true,
      dialog: false,
      editedIndex: -1,
      editedItem: {
        name: '',
        tenant_id: '',
        customer_id: 0,
        site_status: true,
        gps: '',
        country: '',
        zip: '',
        city: '',
        street: '',
        street_number: ''
      },
      defaultItem: {
        name: '',
        tenant_id: '',
        customer_id: 0,
        site_status: true,
        gps: '',
        country: '',
        zip: '',
        city: '',
        street: '',
        street_number: ''
      },
    }
  },
  computed: {
    ...mapGetters(['StateUser']),
  },
  created () {
    this.initialize()
    this.$store.commit('setHeaderTitle', 'Sites')
  },
  methods: {
    async initialize () {
      let response = await this.$http.get('/site/index')
      this.sites = response.data.sites
      this.tenant_id = response.data.tenant
      this.loading = false
    },
    close () {
      this.dialog = false
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem)
        this.editedIndex = -1
      })
    },
    setFileLocation (value) {
      this.editedItem.image = value
    },
    async save () {
      const Site = new FormData();
      Site.append("name", this.editedItem.name);
      Site.append("customer_id", this.editedItem.customer_id);
      Site.append("image", this.editedItem.image);
      Site.append("site_status", this.editedItem.site_status);
      Site.append("gps", this.editedItem.gps);
      Site.append("country", this.editedItem.country);
      Site.append("zip", this.editedItem.zip);
      Site.append("city", this.editedItem.city);
      Site.append("street", this.editedItem.street);
      Site.append("street_number", this.editedItem.street_number);
      Site.append("tenant_id", this.tenant_id);
      let response = await this.$http.post('/site/sites', Site)
      this.$emit('done')
      this.$toast.success("Site "+response.data.site.name+" Created");
      this.close()
    },
  },
};
</script>