<template>
  <v-card @click="selectFile">
    <v-progress-circular v-if="isLoading" indeterminate color="primary"></v-progress-circular>
    
    <v-img
      v-else
      contain
      :src="displayedImage"
      height="250px"
    ></v-img>

    <input type="file" ref="image" hidden @change="uploadFile">
  </v-card>
</template>

<script>
  import S3 from 'aws-s3';
  import {mapGetters} from 'vuex'

  export default {
    name: 'imageUpload',
    props: {
      obj: {
        type: Object,
        required: true
      },
      directory: {
        type: String,
        required: true
      }
    },
    data() {
      return {
        isLoading: false,
        newImage: null
      }
    },
    computed: {
      ...mapGetters(['s3Config']),
      S3Client() {
        const s3 = new S3(this.s3Config)
        s3.config.dirName = this.directory
        return s3
      },
      displayedImage() {
        return this.newImage || this.obj.image || "https://wtec-uploads.s3.eu-central-1.amazonaws.com/assets/placeholder-image.png"
      }
    },
    methods: {
      async uploadFile() {
        
        const file = this.$refs.image.files[0]
        if (!file) return

        this.isLoading = true

        try {
          const data = await this.S3Client.uploadFile(file, this.generateFileName())
          this.newImage = data.location
          this.$emit('located', data.location)
        } catch (error) {
          console.error("Upload failed:", error)
          this.$toast.error("Failed to upload image. Please try again.")
        } finally {
          this.isLoading = false
          this.clearFileInput()
        }
      },

      selectFile() {
        this.$refs.image.click()
      },

      generateFileName() {
        return `${Date.now()}-${Math.random().toString(36).substring(2, 12)}`
      },

      clearFileInput() {
        this.$refs.image.value = null
      },

      clear() {
        this.isLoading = false
        this.newImage = null
        this.clearFileInput()
      }
    },

  }
</script>