<template>
  <v-navigation-drawer
    app
    clipped
    :permanent="$vuetify.breakpoint.mdAndUp"
    :temporary="$vuetify.breakpoint.smAndDown"
    expand-on-hover
  >

    <v-list>
      <template v-for="(item, i) in items">
<!--         <v-row
          v-if="item.heading"
          :key="item.heading"
          align="center">
          <v-col cols="6" class="py-5">
            <span
              style="padding-left: 32px"
              class="text-body-1 subheader"
              :class="(item.heading) ? 'show ' : 'hide'">
              {{ item.heading }}
              </span>
          </v-col>
          <v-col
            cols="6"
            class="text-center">
          </v-col>
        </v-row> -->
        <v-divider
          v-if="item.divider"
          :key="i"
          dark
          class="my-4"
        ></v-divider>
<!--         <v-list-group
          color="primary"
          v-else-if="item.children"
          :key="item.title"
          v-model="item.model"
          append-icon="">
            <template v-slot:prependIcon>
              <v-icon size="28">mdi-image-filter-none</v-icon>
            </template>
            <template v-slot:activator >
              <v-list-item-content >
                <v-list-item-title
                  class="grey--text">
                    {{ item.title }}
                </v-list-item-title>
              </v-list-item-content>
            </template>
            <v-list-item
              v-for="(child, i) in item.children"
              :key="i"
              :to="child.link"
              link>
                <v-list-item-action v-if="child.icon">
                  <v-icon size="">{{ child.icon }}</v-icon>
                </v-list-item-action>
                <v-list-item-content>
                  <v-list-item-title class="grey--text">
                    {{ child.title }}
                  </v-list-item-title>
                </v-list-item-content>
            </v-list-item>
        </v-list-group> -->
        <v-list-item
          color="primary"
          v-if="item.title && item.userRole <= StateUser.role"
          :key="item.title"
          :href="item.href ? item.href : null"
          :to="item.link === '#' ? null : item.link"
          link>
          <v-list-item-action>
            <v-icon
              size="28"
              :color="item.color ? item.color : ''"
            >{{ item.icon }}</v-icon>
          </v-list-item-action>
            <v-list-item-content>
              <v-list-item-title
                class="grey--text"
                link>
                {{ item.title }}
              </v-list-item-title>
            </v-list-item-content>
        </v-list-item>
      </template>
    </v-list>
  </v-navigation-drawer>
</template>

<script>
  import { mapGetters } from 'vuex'
  export default {
    data(){
      return {
        items: [
          //{ title: 'Home', icon: 'mdi-home', link: '/dashboard' },
          { title: 'Sites', icon: 'mdi-office-building', link: '/sites', userRole: 1 },
          { divider: true },
          { title: 'Users', icon: 'mdi-account-multiple', link: '/tenant/users', userRole: 2 },
          { title: 'Products', icon: 'mdi-package-variant', link: '/tenant/products', userRole: 2 },
          //{ title: 'Customers', icon: 'mdi-domain', link: '/tenant/customers', userRole: 2},
          //{ title: 'Suppliers & Brands', icon: 'mdi-domain', link: '/tenant/companies', userRole: 2},
          { divider: true },
          { title: 'Logs', icon: 'mdi-clipboard-text-clock', link: '/tenant/logs', userRole: 2},
          //{ title: 'Support', icon: 'mdi-lifebuoy', link: '/support/vdv' },
        ],
        mini: true
      }
    },
    mounted() {
      this.$store.commit('unsetSite')
      this.$store.commit('unsetPermission')
    },
    computed: {
    ...mapGetters(['UserRole', 'StateUser']),
  },
  }
</script>

<style src="./Sidebar.scss" lang="scss"/>
