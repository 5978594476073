<template>
  <v-container fluid class="sites-page">
    <v-row>
      <v-col cols="12">
        <v-toolbar flat height="90">
          <v-spacer></v-spacer>
          <v-text-field
            v-model="search"
            clearable
            flat
            color="primary"
            hide-details
            prepend-inner-icon="mdi-magnify"
            label="Search"
          ></v-text-field>
          <v-spacer></v-spacer>
          <create-site v-if="StateUser.role >= 3" @done="initialize"></create-site>
        </v-toolbar>
        <v-data-iterator
          :items="sites"
          :search="search"
          :loading="loading"
          item-key="id"
          disable-pagination
          hide-default-footer
          sort-by="updated_at"
          sort-desc
        >
          <template v-slot:default="{ items }">
            <v-row>
              <v-col v-for="item in items" :key="item.id" cols="6" md="2">
                <v-card class="mx-auto my-6" :to="'/site/'+item.id+'/dashboard'">
                  <v-img v-if="item.image" height="200" :src="item.image"></v-img>
                  <v-img v-else height="200" src="https://wtec-uploads.s3.eu-central-1.amazonaws.com/assets/placeholder-image.png" contain></v-img>
                  <v-card-text>
                    <div class="my-4 text-center">
                      {{item.name}}
                    </div>
                  </v-card-text>
                </v-card>
              </v-col>
            </v-row>
          </template>
          <template v-slot:loading>
            <v-row>
              <v-col cols="12" class="text-center mt-12">
                <div>
                  <v-progress-circular :size="80" color="primary" indeterminate></v-progress-circular> 
                </div>
                <div class="mt-8">
                  Loading Sites...
                </div>
              </v-col>
            </v-row>
          </template>
        </v-data-iterator>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { mapGetters } from 'vuex'
import CreateSite from '@/components/Forms/Site/CreateSite';

export default {
  name: "Sites",
  components: {
    CreateSite
  },
  data: () => ({
    sites: [],
    search: '',
    tenant_id: '',
    loading: true,
  }),
  computed: {
    ...mapGetters(['StateUser']),
  },
  created () {
    this.initialize()
    this.$store.commit('setHeaderTitle', 'Sites')
  },
  methods: {
    async initialize () {
      let response = await this.$http.get('/site/index')
      this.sites = response.data.sites
      this.tenant_id = response.data.tenant
      this.loading = false
    },
  },
};
</script>